import { useEffect, useState } from "react";

import { disenoFactura } from '../../../Core/VariablesGlobales';
import {getLocalstore , fetchEmpresa } from '../../../Core/fetchLocalStoreData'
import { __app, __fecha } from "../../../utils/myApp";
import DatosFacturaElectronica from "./Partes/DatosFacturaElectronica";



const FacturaTirilla = ( {documento,user , modo="produccion" , estilos } ) => {

    console.log(documento)

    if(!estilos){

        let estiloGuardado = getLocalstore('disenoFacturaInitialstate');
        if(estiloGuardado){
            estilos = estiloGuardado;
        }else{
            estilos = disenoFactura;;
        }
      
    }


    const empresa =  fetchEmpresa();


    const sum = (obj) => {

        if (!obj){
            return false;
        }

        let sum = 0;
        for (let price of obj ) {
            sum += price.total;
        }
        return sum;
    }

    const TotalPagado        =  (documento.FORMAS_DE_PAGO)  ?     sum(documento.FORMAS_DE_PAGO) : 0;
    const SaldoPendiente     =  documento.MATEMATICAS.SUBTOTAL_3 -  TotalPagado ;
    const EstadoDocumento    =  ( SaldoPendiente != 0 ) ? "Pendiente De Pago" :  "Documento Pagado";
    let cambio               =  null

    if(documento.ESTRAS?.PAGO_CON && Number(documento.ESTRAS.PAGO_CON )  > 0 ){
        if(documento.FORMAS_DE_PAGO[0]){
            cambio      =  Number(documento.ESTRAS.PAGO_CON ) - documento.FORMAS_DE_PAGO[0].total;
        }
        
    }

    

    documento.MATEMATICAS.PROPINA = ( documento.MATEMATICAS.PROPINA ) ? documento.MATEMATICAS.PROPINA : 0;
    
    /** Estilos */
    const [id, setID] = useState();


    
    
    useEffect(() => {
        
        setID(modo == "pruebas" ? "EasyPrintAnd_" : "EasyPrintAnd");

    },[])




    return (
        <div >
            { (estilos && documento.TIPO_DOCUMENTO )&& 
            
            <div id={id} > 
            
                <div style={estilos.Tirillacontenedor} >


                    
                    <div style={estilos.TirillaSubcontenedor }>
                        
                        <div className="TirillaTextCenter">     
                            { (user.CONFIGURACIONES_TABLE.mostrar_logo_factura && user.CONFIGURACIONES_TABLE.mostrar_logo_factura == 1)  && 
                            <>
                                <img src={empresa.LOGO} className="img-fluid rounded-start" alt="..." />
                            </>                           
                            }
                            <h4 className="TirillaM0">{user.ESTACION.nombre}</h4>
                            <b className="Tirilla11px"> NIT : {user.ESTACION.nit}</b>
                            <p style={{marginTop:'-2px'}}>
                                {user.ESTACION.telefono} <br/> 
                                {user.ESTACION.direccion}<br/>
                                {user.ESTACION.municipio} <br/>
                            </p>
                        </div>

                        <p className="TirillaTextCenter">   { documento.id_server && <b>{documento.TIPO_DOCUMENTO.descripcion}</b> || <b> PRE-FACTURA.  </b> } </p>

                        {/*<div className="TirillaTextCenter">
                                Estado de Factura <br/>
                        </div>*/}



                        {/* Informacion Factura */}
                        <div>

                            <div className="TitillaBox">
                              
                              
                                <div style={estilos.font9} className="TitillarDB "> 
                                    
                                    Fecha de Emision  {documento.FORM && <> {__fecha.formatoFecha({$fecha : documento.fechaFinal})} </> } </div> 
            
                                
                                { !documento.RESPONSE_HTTP_FACTURA_ELECTRONICA && 
                                    <>
                                        {  documento.EstadoServidor == 1 && 
                                            <div style={estilos.font9} className="TitillarDB "> 
                                                Recibo#  { ( documento.NUMERACION &&  documento.NUMERACION.numero_completo && documento.NUMERACION.numero_completo.length > 1 ) && documento.NUMERACION.numero_completo  ||   documento.id_server  }         
                                            </div> 
                                            ||
                                            <div style={estilos.font9} className="TitillarDB "> 
                                                Cod.Temporal :  <b>{documento.id }  </b>
                                            </div> 
                                        }

                                    </>
                                
                                }    
                                





                                {/* Resolucion de factura Electronica **/}
                                {!(documento?.RESPONSE_HTTP_FACTURA_ELECTRONICA?.CUFE) &&       
                                    <>{ (documento.NUMERACION && documento.NUMERACION.texto_factura ) && <div style={{fontSize: '9px'}}> { documento.NUMERACION.texto_factura } </div> }</>
                                } 

                            </div>

                            

                            {documento?.OBJETO && (
                                <p>
                                    {documento?.placa && <span style={{fontSize:'20px'}}> <b>Placa#{documento.placa}</b> </span>}
                                    {documento?.OBJETO?.nombre && <>Descripción: {documento.OBJETO.nombre}</>}<br/>
                                    {documento?.OBJETO?.nombreCategoria && <>Categoría: {documento.OBJETO.nombreCategoria}</>}<br/>
                                    {documento?.patio_entrada_fecha && (
                                        <>
                                            Entrada: {documento.patio_entrada_fecha}<br/>
                                            Salida: {documento.patio_salida_fecha}<br/>
                                            Tiempo: {documento.patio_tiempo}
                                        </>
                                    )}
                                </p>
                            )}



                            { (documento.FORM && documento.FORM.mesanombre ) &&
                            <div className="TitillaBox"> 
                                <div style={estilos.font9} className="TitillarDB ">Mesa#{documento.FORM.mesanombre}</div>
                            </div>
                            }  


                            { ( documento.cuentAbiertaFirebaseId ) &&
                            <div className="TitillaBox"> 
                                <div style={estilos.font9} className="TitillarDB ">NumeroCta#{documento.cuentAbiertaFirebaseId}</div>
                            </div>
                            }  

                        
                        
                            {/*<div className="TitillaBox"> Si Señora. Yo por ahora no tengo oficina. El trabajo es remoto. Entonces para facilitarte las cosas nos podemos reunir en el negocio que esta alfrente de la cancha sintetica de policarpa.
                                la idea es sentarnos unos 30 a 40min. Te explico de que se trata. Pero el trabajo es la mayoria del tiempo desde su casa por internet. 
                                <div style={estilos.font9} className="TitillarDB ">  Estado Servidor:</div>
                            </div> */}
                            
                            <div className="TitillaBox"> 
                            
                                <div style={estilos.font9} className="TitillarDB ">  Cajero: { documento.FORM &&  documento.FORM.nombreCajero }</div>
                            
                                {
                                    (documento.FORM &&  documento.FORM.nombre_vendedor && documento.FORM.nombre_vendedor.trim().length > 0  )  && 

                                    <div style={estilos.font9} className="TitillarDB ">   Vendedor : { documento.FORM &&  documento.FORM.nombre_vendedor} </div>
                                }

                            {/*}
                                <div style={estilos.font9} className="TitillarDB  ">  Turno :</div>
                                { (documento.FORM && documento.FORM.Operadores.length > 0) && <div className='TitillarDB'>  Operador :{documento.FORM.Operadores}</div>}
                            /*/}
                            </div>
                        </div>






                        {/* Informacion Tercero */}    
                        { documento.TERCERO?.razonSocial &&      
                            <div className='mt-2'>
                                <div className='TitillaBox'>
                                    <div className='TitillarDB'>  Cliente : {documento.TERCERO.razonSocial}</div> 
                                </div>
                                <div className='TitillaBox'>
                                    <div className='TitillarDB'>  Nit/Cc : {documento.TERCERO.identificacion} </div> 
                                </div>
                                <div className='TitillaBox'>
                                    <div className='TitillarDB'>  Teléfono : {documento.TERCERO.telefono} </div> 
                                </div>
                            </div>
                        }


                
                    
                    
                            
                    














                        {/* Header Productos Lista */}
                        <div className="">
                            <div style={estilos.LineaProductos.titulo} className="TirillaTextCenter"> 
                                <b>Productos/Servicios</b>
                            </div>
                            <div className="lineaprecio">
                                <span></span>
                                <span style={estilos.LineaProductos.subtitulo}  >Precio</span>
                                <span style={estilos.LineaProductos.subtitulo} >Cant</span>
                                <span style={estilos.LineaProductos.subtitulo} >Subtotal</span>
                            </div>
                        </div> 



                        
                        
                        {/* Cuerpo Productos Lista */}
                        <div > 
                            
                            {documento.PRODUCTOS.map( (pro,index) => 
                                <div key={index} className="Ti-border TitillaBox" > 
                                    <span className="block "> 
                                        
                                        { estilos.SWITCH_SHOW_FIELD && estilos.SWITCH_SHOW_FIELD.codigo == 1 &&
                                        <>
                                        <span style={estilos.LineaProductos.codigo}> {pro.id_2} </span> |
                                        </>
                                        }
                                        <span style={estilos.LineaProductos.nombre} > {__app.TextoMinimo(pro.nombre ,  20)}  </span>  
                                    </span>                     
                                
                                    <span className="block Tr_textoend  "  > 
                                        <div className="lineaprecio">    
                                            <span></span>
                                            <span style={estilos.LineaProductos.precio}>{__app.formato_decimal({total :pro.MATEMATICAS.PRECIO_3})  }</span>  
                                            <span style={estilos.LineaProductos.cantidad}>{pro.MATEMATICAS.CANTIDAD} </span> 
                                            <span style={estilos.LineaProductos.subtotal}>{__app.formato_decimal({total :pro.MATEMATICAS.SUBTOTAL_3}) } </span>
                                        </div>
                                    </span>
                                </div>
                            )}
                        </div>  













                        {/* Totales */}  
                        <div   className="TirillaTextCenter Ti-mt block"> 
                            <p>
                                {
                                    documento.EstadoServidor == 1 && 
                                    <>
                                        Subtotal: {  __app.formato_decimal(  {total : documento.MATEMATICAS.SUBTOTAL_1 } )}  <br/>
                                        Descuentos: {  __app.formato_decimal(  {total : documento.MATEMATICAS.DESCUENTO_LINEA } )}  <br/> 
                                        Impuestos  : {  __app.formato_decimal( {total :  documento.MATEMATICAS.IMPUESTO }  )} <br/>
                                    </>
                                } 
                                Neto : {  __app.formato_decimal( {total :  documento.MATEMATICAS.SUBTOTAL_3 }  )} <br/>                              
                                {  documento.MATEMATICAS.PROPINA >  0 && <span style={{display:'block'}}>Propina : $ { documento.MATEMATICAS.PROPINA}</span>  }
                                <b>TOTAL A PAGAR  $ { __app.formato_decimal({total : documento.MATEMATICAS.SUBTOTAL_3 + documento.MATEMATICAS.PROPINA })  } </b>
                            </p>    
                        </div>
                        


                        {/* Detalle de impuestos  */}         
                        { (documento.MATEMATICAS.IMPUESTOS &&  documento.MATEMATICAS.IMPUESTOS.length > 0 ) && 
                            <div className="contenedorPagos" style={{marginBottom : '10px'}} >
                        
                                <span className="font12 TirillaTextCenter block">
                                   Detalle de los Impuestos
                                </span>
                                    
                                {documento.MATEMATICAS.IMPUESTOS && documento.MATEMATICAS.IMPUESTOS.map( (impuesto,index) =>  
                        
                                    <div key={index} className="lineapagos">
                                        <span className="font12" > {impuesto.nombre}</span>
                                        <span className="font12" > ({impuesto.importe}%)</span>
                                        <span className="font12" >$ { __app.formato_decimal({total : impuesto.TOTAL_IMPUESTO}) } </span>
                                    </div>
                                                  
                                )}
                            </div>
                        }




                        {/* Formas de Pagos */}         
                         {  
                            (documento.FORMAS_DE_PAGO && 
                            documento.FORMAS_DE_PAGO.length > 0 && 
                            Number(documento?.TIPO_DOCUMENTO?.ct_master_id) >= 1)  
                            &&  <div className="contenedorPagos" >
                        
                            <span className="font12 TirillaTextCenter block">
                                Formas de pagos
                            </span>
                                
                            {documento.FORMAS_DE_PAGO && documento.FORMAS_DE_PAGO.map( (pago,index) =>  
                                <span key={index}>                                
                                {
                                    pago.total != 0   &&   
                                    <div key={index} className="lineapagos">
                                        <span className="font12" > {pago.nombre}</span>
                                        <span className="font12" >Codigo Caja : {pago.banco_id}</span>
                                        <span className="font12" >$ { __app.formato_decimal({total : pago.total}) } </span>
                                    </div>
                                }
                                </span>                                
                            )}


                            {
                                documento.MATEMATICAS.TOTAL_SALDO_FAVOR &&  documento.MATEMATICAS.TOTAL_SALDO_FAVOR > 0  && (
                                <div  className="lineapagos">
                                    <span className="font12" >Uso de  Saldo a Favor  </span>
                                    <span className="font12" >$ { __app.formato_decimal({total :    documento.MATEMATICAS.TOTAL_SALDO_FAVOR }) } </span>
                                </div>)
                                ||
                                <></>
                            }    
                            
                            {cambio && 
                                <span className="">      
                                    <h4>
                                        PAGO CON : { Number(documento.ESTRAS.PAGO_CON ) } <br/>
                                        <span>Cambio : {cambio} </span>
                                    </h4>
                                </span> 
                            }
        
                            { /* <div className="lineapagos">    
                                {   
                                SaldoPendiente != 0   &&
                                <div>
                                    <span className="font12">Saldo Pendiente</span>
                                    <span className="font12">$ 1000.000 </span>
                                </div>
                                }

                                {
                                TotalPagado != 0   &&
                                    <div>          
                                        <span className="font12">Total Pagado</span>
                                        <span className="font12">$ 1000.000 </span>
                                    </div>
                                }
                            </div> */}
                        </div>}
                        


                        {/* Formas de Pagos */}     
                        <div className="mt-5  TirillaTextCenter text-center">

                            { (user.CONFIGURACIONES_TABLE.factura_texto_pequeno && user.CONFIGURACIONES_TABLE.factura_texto_pequeno.trim().length > 0)  && 
                                <div className="text-center mt-2">  
                                    <br/>   
                                    <br/>      
                                    <center> <span className="text-center d-block">{user.CONFIGURACIONES_TABLE.factura_texto_pequeno}</span></center>
                                </div>    
                            }


                            { (user.CONFIGURACIONES_TABLE.factura_texto_pie_pagina && user.CONFIGURACIONES_TABLE.factura_texto_pie_pagina.trim().length > 0)  && 
                                <div className="text-center mt-2">     
                                    <center> <b className="text-center d-block mt-1">{user.CONFIGURACIONES_TABLE.factura_texto_pie_pagina}</b></center>
                                </div>     
                            }
                        </div> 




                        {/** Campos de Factura Electronica */}    
                        { documento.RESPONSE_HTTP_FACTURA_ELECTRONICA &&  <DatosFacturaElectronica documento={documento} /> }


                        {/* Formas de Pagos */}     
                        { estilos.SWITCH_SHOW_FIELD && estilos.SWITCH_SHOW_FIELD.saltolinea == 1 && 
                        <>                   
                            <div style={{
                                'marginTop': '30px',
                                'textalign': 'center',
                                'height'  : '15px',
                                'overflow': 'hidden',
                            }}>
                            - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -- - - - - - - - - - - - - - - - - - - -    
                            </div>



                            <div style={{
                                'marginTop': '50px',
                                'textAlign': 'center',
                                'height'  : '15px',
                                'overflow': 'hidden',
                            }}>
                            - - - - - - - - - - - - - - - - - - - - - - - - - - - - -- - - - - - - - - - - - - - - - - - - - -    
                            </div>
                        </>}
                    </div>
                </div>

            
                    
            </div>}
        </div>

    )};

export default FacturaTirilla