import React, { useEffect } from 'react';
import FacturaTirilla from '../Document/PlantillasImpresion/FacturaTirilla';
import { getLocalstore , setLocalStore} from '../../Core/fetchLocalStoreData';
import { disenoFactura } from '../../Core/VariablesGlobales';
import { useState } from 'react';
import prindiv from 'easyprintand';

import { NotificationManager} from 'react-notifications';
import { ExtraerTabla } from '../../Core/Dixie';

const DisenoTirilla = () => {



    const [user , setUser ] = useState();

    const [document , setDocument ] = useState(null);

    /* Variable insignificante pero si no esta no funciona esta pagina */
    const [primeracarga , setPrimeraCarga] = useState(0);



    const handlePrint = () => {
        console.log(document)
        if (document){
            prindiv('EasyPrintAnd' ,  { extraCss : './tirilla.css' } , "pruebas")
        }
       
    }
    
    const ultimoDocumento = async () => {
        
        let DocumentosDixie = await  ExtraerTabla('documentos');
        const ultimoElemento = DocumentosDixie[ DocumentosDixie.length - 1 ];
        setDocument(ultimoElemento);

    }



    useEffect( () => {
        
        ultimoDocumento();
        setUser( getLocalstore("user"));


        let estiloInicial = getLocalstore('disenoFacturaInitialstate');
        if(estiloInicial){
            setEstilos(estiloInicial);
        }else{
            setEstilos(disenoFactura);
        }

        setTimeout( () => {
            setPrimeraCarga(2);
          
            prindiv('EasyPrintAnd' ,  { extraCss : './tirilla.css' } , "pruebas")
          
            
        } , 1000 )

    } , [ ] );









    const fuentes = ['Arial, sans-serif'   ,   'Helvetica, sans-serif'  ,   'Verdana, sans-serif'  ,   'Trebuchet MS, sans-serif'  ,   'Gill Sans, sans-serif' ,   'Noto Sans, sans-serif' ,   'Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif'  ,   'Optima, sans-serif' ,   'Arial Narrow, sans-serif' ,   'Arial Narrow, sans-serif'    ,   'serif: normal fonts with serifs'         ,   'Times, Times New Roman, serif'           ,   'Didot, serif'             ,   'Georgia, serif'    ,   'Palatino, URW Palladio L, serif'    ,   'Bookman, URW Bookman L, serif'    ,   'New Century Schoolbook, TeX Gyre Schola, serif'    ,   'American Typewriter, serif'    ,   'serif'    ,   'monospace: fixed-width fonts'    ,   'Andale Mono, monospace'   ,   'Courier New, monospace'  ,   'Courier, monospace'   ,   'FreeMono, monospace'   ,   'OCR A Std, monospace'   ,   'DejaVu Sans Mono, monospace'   ,   'monospace'  ,   'cursive'   ,   'Comic Sans MS, Comic Sans, cursive'   ,   'Apple Chancery, cursive'   ,   'Bradley Hand, cursive'   ,   'Brush Script MT, Brush Script Std, cursive'   ,   'Snell Roundhand, cursive'   ,   'URW Chancery L, cursive'   ,   'cursive'   ];

    const impresoras = [

        {label : "POS 80mm" , value : '302.36px' },
        {label : "POS 57mm" , value : '215.43px' }
    ]


    const [estilos, setEstilos] = useState(null);

    const [fuentePorcentaje , SetfuentePorcentaje] = useState(0);




    const aumentarFuentesProductos = (numero , clave ) => {
        let copyestilos =  JSON.parse(JSON.stringify(estilos)) 
        copyestilos.LineaProductos[clave].fontSize  = copyestilos.LineaProductos[clave].fontSize + numero;
        setEstilos(copyestilos);
      
    }
    
    const aumentarConfigglobalSubConte = (numero , clave  ) => {
        let copyestilos =  JSON.parse(JSON.stringify(estilos)) 
        copyestilos.TirillaSubcontenedor  = {...copyestilos.TirillaSubcontenedor , [clave] : copyestilos.TirillaSubcontenedor[clave] + numero } 
        setEstilos(copyestilos);
      
    }
    
    const aumentarConfigglobal = (valor , clave  ) => {
        let copyestilos =  JSON.parse(JSON.stringify(estilos)) 
        copyestilos.Tirillacontenedor  = {...copyestilos.Tirillacontenedor , [clave] : valor } 
        console.log(copyestilos);
        setEstilos(copyestilos);
      
    }


    const cambiarFuente = (e) => {
        console.log(e.target.value);
        let copyestilos =  JSON.parse(JSON.stringify(estilos)) 
        copyestilos.Tirillacontenedor.fontFamily  = e.target.value;
        setEstilos(copyestilos);
        
    }

    useEffect(() => {
        console.log(primeracarga)
        if(primeracarga >= 2 ){
            handlePrint();
            setPrimeraCarga(primeracarga+ 1 );
        }else{
            setPrimeraCarga(primeracarga+ 1 );
        }
            


    } , [estilos])

    const propiedadesMasMenos = {

        TirillaSubcontenedor : [
            {etiqueta: "Margen Superior" , clave :  "marginTop" } ,
            {etiqueta: "Margen Izquierdo" , clave :  "marginLeft" } ,
            {etiqueta: "Margen Derecho" , clave :  "marginRight" } ,
  
        ]
    }



    const Handleguardarcambios = () => {
        setPrimeraCarga(2);
        NotificationManager.success('Cambios guardados');
        setLocalStore('disenoFacturaInitialstate' , estilos);

    }


    const handleShowfield = (event , clave ) => {

        let resultado = 0;
        if (event.target.checked) resultado = 1 ;

        let copyestilos =  JSON.parse(JSON.stringify(estilos))   
        copyestilos.SWITCH_SHOW_FIELD  = {...copyestilos.SWITCH_SHOW_FIELD , [clave] : resultado } 
        console.log(copyestilos);
        setEstilos(copyestilos);
    }

    const TamanoPerzonalidado = () => {
        var name = prompt("Ingrese Los Pixeles Personalizados")
        aumentarConfigglobal(name + "px" , 'width')
    }
    
    return (
        <div>
            <h2>Diseño de Factura</h2>

            {estilos && 
            <div className='row'>
                <div className='col-3'>

                 <div className='shadow-sm p-3 mb-5 bg-body rounded'>
                    <h6>Globales Config</h6>

                    <div  className='mt-3'>
                        <b>Fuente</b>
                        <select   onChange={cambiarFuente} className="form-select" >   
                            {fuentes.map( (fuen, index) =>
                                <option  value={fuen} key={index} >{fuen}</option>
                            )}
                        </select>
                    </div>

                   <div  className='mt-3' >
                    <b> Tamano del Papel</b>
                    <b> Tamano del Papel ({estilos.Tirillacontenedor.width})</b>  <span onClick={TamanoPerzonalidado} className='link' >Tamaño Perzonalizado</span> <br/>
                        <select  defaultValue={estilos.Tirillacontenedor.width} onChange={(e) => aumentarConfigglobal(e.target.value , 'width')} className="form-select" >   
                            {impresoras.map( (impre, index) =>
                                <option  value={impre.value} key={index} >{impre.label}</option>
                            )}
                        </select>
                   </div>
                                

                   <div  className='mt-3' >           
                    {propiedadesMasMenos.TirillaSubcontenedor.map( (pro , index) => 
                        <p key={index}>
                            {pro.etiqueta}
                            <button  className='float-end ms-1 btn btn-sm btn-principal' onClick={() => aumentarConfigglobalSubConte( -1 , pro.clave ) }> <i className="fa-solid fa-minus"></i>  </button>
                            <button  className='float-end ms-1 btn btn-sm btn-principal' onClick={() => aumentarConfigglobalSubConte( 1 , pro.clave ) }> <i className='fa fa-plus' /> </button>
                            <span className='mt-2 float-end badge bg-primary'>{estilos.TirillaSubcontenedor[pro.clave]}px </span>
                        </p>
                    )}
                    </div>
                   

                </div>   

                <div className="accordion" id="accordionPanelsStayOpenExample">
                
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                            Fuente Linea de Productos
                        </button>
                        </h2>
                        <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse">
                            <div className="accordion-body">
                   

                                <p>
                                    Titulo
                                    <button  className='float-end ms-1 btn btn-sm btn-principal' onClick={() => aumentarFuentesProductos( -1 , 'titulo' ) }> <i className="fa-solid fa-minus"></i>  </button>
                                    <button  className='float-end ms-1 btn btn-sm btn-principal' onClick={() => aumentarFuentesProductos( 1 , 'titulo' ) }> <i className='fa fa-plus' /> </button>
                                    <span className='mt-2 float-end badge bg-primary'>{estilos.LineaProductos.titulo.fontSize}px </span>
                                </p>

                                <p>
                                    Subtitulos 
                                    <button  className='float-end ms-1 btn btn-sm btn-principal' onClick={() => aumentarFuentesProductos( -1, 'subtitulo' ) }> <i className="fa-solid fa-minus"></i>  </button>
                                    <button  className='float-end ms-1 btn btn-sm btn-principal' onClick={() => aumentarFuentesProductos( 1 , 'subtitulo') }> <i className='fa fa-plus' /> </button>
                                    <span className='mt-2 float-end badge bg-primary'>{estilos.LineaProductos.subtitulo.fontSize}px </span>
                                </p>

                                <p>
                                    <input class="form-check-input" type="checkbox" defaultChecked={ estilos.SWITCH_SHOW_FIELD['codigo'] && estilos.SWITCH_SHOW_FIELD['codigo'] && estilos.SWITCH_SHOW_FIELD['codigo'] == 1  ? true : false } onChange={(e) => handleShowfield(e , 'codigo') }  /> Codigo  
                                    <button  className='float-end ms-1 btn btn-sm btn-principal' onClick={() => aumentarFuentesProductos( -1 , 'codigo' ) }> <i className="fa-solid fa-minus"></i>  </button>
                                    <button  className='float-end ms-1 btn btn-sm btn-principal' onClick={() => aumentarFuentesProductos( 1 , 'codigo') }> <i className='fa fa-plus' /> </button>    
                                    <span className='mt-2 float-end badge bg-primary'>{estilos.LineaProductos.codigo.fontSize}px </span>
                                </p>

                                <p>
                                    Nombre 
                                    <button  className='float-end ms-1 btn btn-sm btn-principal' onClick={() => aumentarFuentesProductos( -1, 'nombre' ) }> <i className="fa-solid fa-minus"></i>  </button>
                                    <button  className='float-end ms-1 btn btn-sm btn-principal' onClick={() => aumentarFuentesProductos( 1 , 'nombre') }> <i className='fa fa-plus' /> </button>    
                                    <span className='mt-2 float-end badge bg-primary'>{estilos.LineaProductos.nombre.fontSize}px </span>
                                </p>

                      

                                <p>
                                    Cantidad 
                                    <button  className='float-end ms-1 btn btn-sm btn-principal' onClick={() => aumentarFuentesProductos( -1 , 'cantidad') }> <i className="fa-solid fa-minus"></i>  </button>
                                    <button  className='float-end ms-1 btn btn-sm btn-principal' onClick={() => aumentarFuentesProductos( 1, 'cantidad' ) }> <i className='fa fa-plus' /> </button>    
                                    <span className='mt-2 float-end badge bg-primary'>{estilos.LineaProductos.cantidad.fontSize}px </span>
                                </p>

                                <p>
                                    Precio total x Linea
                                    <button  className='float-end ms-1 btn btn-sm btn-principal' onClick={() => aumentarFuentesProductos( -1 , 'subtotal') }> <i className="fa-solid fa-minus"></i>  </button>
                                    <button  className='float-end ms-1 btn btn-sm btn-principal' onClick={() => aumentarFuentesProductos( 1, 'subtotal' ) }> <i className='fa fa-plus' /> </button>    
                                    <span className='mt-2 float-end badge bg-primary'>{estilos.LineaProductos.subtotal.fontSize}px </span>
                                </p>


                                <p>
                                    Precio 
                                    <button  className='float-end ms-1 btn btn-sm btn-principal' onClick={() => aumentarFuentesProductos( -1, 'precio' ) }> <i className="fa-solid fa-minus"></i>  </button>
                                    <button  className='float-end ms-1 btn btn-sm btn-principal' onClick={() => aumentarFuentesProductos( 1 , 'precio') }> <i className='fa fa-plus' /> </button>    
                                    <span className='mt-2 float-end badge bg-primary'>{estilos.LineaProductos.precio.fontSize}px </span>
                                </p>


                                 <p>         
                                    <input class="form-check-input" type="checkbox" defaultChecked={ estilos.SWITCH_SHOW_FIELD['saltolinea'] && estilos.SWITCH_SHOW_FIELD['saltolinea'] && estilos.SWITCH_SHOW_FIELD['saltolinea'] == 1  ? true : false } onChange={(e) => handleShowfield(e , 'saltolinea') }  /> Mostrar Saltos de linea al final   
                                    { estilos.SWITCH_SHOW_FIELD['saltolinea'] === 1 && <span className='badge bg-success'>Si</span> || <span className='badge bg-danger'>No</span> } 
                                </p>

                            </div>
                        </div>
                    </div>
                    
                </div>

                  
                    { false && <button className='float-end btn btn-info mt-3' onClick={handlePrint}>Cargar vista</button>}
                    {primeracarga >= 3 && <button className='float-end ms-2 btn btn-principal mt-3' onClick={Handleguardarcambios}>Guardar Cambios</button> }
                </div>



                            {/*{paddingTop : 40 ,  flexWrap: 'nowrap' , justifyContent: 'space-evenly',  display: 'flex', alignContent :  'center' ,  background: '#ccc',position: 'relative', height: '800px','padding': '0px','margin':'0px'}*/}
                <div className='col-9' >
                
                    {document && <FacturaTirilla  documento={document} user={user}  estilos={estilos}   /> }
                    
                    {!document && <span>
                        Para poder configurar la impresión debes crear un documento desde el punto de venta. <br />
                        Una vez lo cree <b>no lo factures</b>. Regresas aquí para configurar la vista previa de tu impresión. 
                    </span> }
                    <div className='pintDivtest'> </div>
                  
                   



                </div>
            </div>}

        </div>
    );
};

export default DisenoTirilla;